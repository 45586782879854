import React, { useState } from 'react';
import { useController } from './controller';
import InputSelect from '../Inputs/SelectWithSearch';
import * as S from './styles';

const EditCompanyFiscalData = (props: any) => {
    const controller = useController(props);

    return (
        <S.main active={controller.modalActive} animationOpacity={props.modalActive} onClick={() => props.setModalActive((active: boolean) => !active)}>
            <S.modal step={controller.step} onClick={(e) => e.stopPropagation()} animationInOut={props.modalActive}>
                <S.header>
                    <S.title>Editar dados fiscais</S.title>
                    <S.close onClick={() => {
                        controller.setStep(0);
                        props.setModalActive((active: boolean) => !active)
                    }}>X</S.close>
                </S.header>
                <S.body>
                    <S.stepContainer>
                        {controller.step == 0 ?
                            <S.step active={controller.step == 0} mobileMod={true}>
                                <S.inputsArea>
                                    <S.inputContainer>
                                        <S.inputLabel>Código Municipal:</S.inputLabel>
                                        <S.inputSelect onChange={(e: any) => { controller.handleFiscalInfoChange(e) }} name='fiscal.nf_ibge_city_code' value={controller.companyInfo.fiscal.nf_ibge_city_code}>
                                            <S.inputOption value=''>Selecione</S.inputOption>
                                            {controller.general.info_ibge.map((item: any) => { return <S.inputOption value={item.code}>{item.name} - {item.uf} - {item.code}</S.inputOption> })}
                                        </S.inputSelect>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Regime Tributário:</S.inputLabel>
                                        <S.inputSelect onChange={(e: any) => { controller.handleFiscalInfoChange(e) }} name='fiscal.tributary_regime' value={controller.companyInfo.fiscal.tributary_regime}>
                                            <S.inputOption value=''>Selecione</S.inputOption>
                                            <S.inputOption value='1'>Simples Nacional</S.inputOption>
                                            <S.inputOption value='2'>Simples Nacional - excesso de sublimite de receita bruta</S.inputOption>
                                            <S.inputOption value='3'>Regime Normal</S.inputOption>
                                        </S.inputSelect>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>CNAE Principal:</S.inputLabel>
                                        <S.inputSelect onChange={(e: any) => { controller.handleFiscalInfoChange(e) }} name='fiscal.main_cnae' value={controller.companyInfo.fiscal.main_cnae}>
                                            <S.inputOption value=''>Selecione</S.inputOption>
                                            {controller.general.cnaes.map((item: any) => { return <S.inputOption value={item.code}>{`${item.code} - ${item.description != '' ? item.description : 'Descrição não informada'}`}</S.inputOption> })}
                                        </S.inputSelect>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Inscrição municipal:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleFiscalInfoChange(e) }} name='fiscal.nf_city_inscription' value={controller.companyInfo.fiscal.nf_city_inscription}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Inscrição estadual:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleFiscalInfoChange(e) }} name='fiscal.nf_state_inscription' value={controller.companyInfo.fiscal.nf_state_inscription}></S.input>
                                    </S.inputContainer>
                                </S.inputsArea>
                            </S.step>
                            : null}
                    </S.stepContainer>
                    <S.saveBtn onClick={() => controller.updateInfo()}>
                        <controller.general.DefaultLoadingFit active={controller.loading} borderRadius={4} size={15} />
                        Salvar
                    </S.saveBtn>
                </S.body>
            </S.modal>
        </S.main>
    );
}

export default EditCompanyFiscalData;