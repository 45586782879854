import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultItemModalProps } from '../../../utils/types';
import Avatar from 'react-avatar';
import { colors } from '../../../utils/colors';
import general from '../../../utils/general';
import InputText from '../Inputs/Text';
import InputSelect from '../Inputs/Select';
import DateTime from '../Inputs/DateTime';
import Checkbox from '../Inputs/Checkbox';
import SelectWithSearch from '../Inputs/SelectWithSearch';

const DefaultEditModal = (props: defaultItemModalProps) => {
    const controller = useController(props);
    return (
        <S.main active={controller.modalShow} animationOpacity={controller.modalShow} onMouseDown={() => props.setModalActive((active: boolean) => !active)}>
            <S.confirmDeleteModal active={controller.deleteConfirmationModal} onMouseDown={(e) => {
                e.stopPropagation()
                controller.setDeleteConfirmationModal(false)
            }}>
                <S.confirmDeleteModalContent onMouseDown={(e) => e.stopPropagation()}>
                    <S.confirmDeleteModalTitle>Deletar item</S.confirmDeleteModalTitle>
                    <S.confirmDeleteModalText>Tem certeza que deseja deletar este item?</S.confirmDeleteModalText>
                    <S.confirmDeleteModalButtons>
                        <S.confirmDeleteModalButton onMouseDown={() => controller.deleteItem()}>Sim</S.confirmDeleteModalButton>
                        <S.confirmDeleteModalButton onMouseDown={() => controller.setDeleteConfirmationModal(false)}>Não</S.confirmDeleteModalButton>
                    </S.confirmDeleteModalButtons>
                </S.confirmDeleteModalContent>
            </S.confirmDeleteModal>

            <S.confirmLeadToClientModal active={controller.convertLeadToClientConfirmationModal} onMouseDown={(e) => {
                e.stopPropagation()
                controller.setConvertLeadToClientConfirmationModal(false)
            }}>
                <S.confirmLeadToClientModalContent onMouseDown={(e) => e.stopPropagation()}>
                    <S.confirmLeadToClientModalTitle>Converter Lead</S.confirmLeadToClientModalTitle>
                    <S.confirmLeadToClientModalText>Tem certeza que deseja converter o LEAD <br /> <b>[ {controller.item?.name ?? 'Não informado'} ]</b> para CLIENTE?</S.confirmLeadToClientModalText>
                    <S.confirmLeadToClientModalButtons>
                        <S.confirmLeadToClientModalButton onMouseDown={() => controller.convertLeadToClient()}>Sim</S.confirmLeadToClientModalButton>
                        <S.confirmLeadToClientModalButton onMouseDown={() => controller.setConvertLeadToClientConfirmationModal(false)}>Não</S.confirmLeadToClientModalButton>
                    </S.confirmLeadToClientModalButtons>
                </S.confirmLeadToClientModalContent>
            </S.confirmLeadToClientModal>

            <S.expandedInfoModal active={controller.expandedInfoModal} onMouseDown={(e) => {
                e.stopPropagation()
                controller.setExpandedInfoModal(false)
            }}>
                <S.expandedInfoModalContent onMouseDown={(e: any) => e.stopPropagation()}>
                    <S.expandedInfoModalHeader>
                        <S.expandedInfoModalTitle>Informações expandidas</S.expandedInfoModalTitle>
                        <S.expandedInfoModalClose icon={general.icons.faTimes} onMouseDown={() => controller.setExpandedInfoModal(false)} />
                    </S.expandedInfoModalHeader>
                    <S.expandedInfoFields>
                        {controller.item.infos ? controller.item.infos.map((field: typeof controller.item.infos[0], index: number) => {
                            return (
                                <>
                                    <S.expandedInfoModalField key={index}>
                                        <S.expandedInfoModalFieldTitle>Campo ({field.name}): <S.expandedInfoModalCopyIcon icon={general.icons.faCopy} onMouseDown={() => {
                                            general.copyToClipboard((field.type == "checkbox" ? (field.value ? "Sim" : "Não") : (field.value == '' || field.value == null) ? 'Não informado' : field.value.toString()))
                                            general.notify('Campo (' + field.name + ') copiado para a área de transferência', 'success')
                                        }} /></S.expandedInfoModalFieldTitle>
                                        <S.expandedInfoModalFieldText>{field.type == "checkbox" ? (field.value ? "Sim" : "Não") : (field.value == '' || field.value == null) ? 'Não informado' : field.value}</S.expandedInfoModalFieldText>
                                    </S.expandedInfoModalField>
                                </>
                            )
                        }) : null}
                    </S.expandedInfoFields>
                </S.expandedInfoModalContent>
            </S.expandedInfoModal>

            <S.incrementStockModal active={controller.incrementStockModal} onMouseDown={(e) => {
                e.stopPropagation()
                controller.setIncrementStockModal(false)
            }}>
                <S.incrementStockModalContent onMouseDown={(e) => e.stopPropagation()}>
                    <S.incrementStockModalTitle>Adicionar ao estoque</S.incrementStockModalTitle>
                    <S.incrementStockModalInput value={controller.amountToIncrement} onChange={(e) => controller.setAmountToIncrement(parseInt(e.target.value))} type='number' placeholder='Quantidade a adicionar' />
                    <S.incrementStockModalButtons>
                        <S.incrementStockModalButton onMouseDown={() => controller.incrementStock()}>Adicionar</S.incrementStockModalButton>
                        <S.incrementStockModalButton onMouseDown={() => controller.setIncrementStockModal(false)}>Cancelar</S.incrementStockModalButton>
                    </S.incrementStockModalButtons>
                </S.incrementStockModalContent>
            </S.incrementStockModal>

            <S.modal onMouseDown={(e) => e.stopPropagation()} animationInOut={controller.modalShow}>
                <controller.general.DefaultLoadingFit active={controller.deleteLoading} borderRadius={5} size={35} />
                <S.container>
                    <S.title>Opções</S.title>
                    {controller.item.avatar ? (<S.avatar src={props.item.avatar} />) : (<S.defaultAvatar textSizeRatio={3} maxInitials={2} round='5px' size='150px' name={controller.item.title ?? controller.item.name} style={{
                        alignSelf: 'center',
                    }} />)}
                    <S.options>
                        {(props.canBeDeleted ?? true) ? <S.uniqueOption color={controller.general.utilColors.pureRed} onMouseDown={() => controller.setDeleteConfirmationModal(true)}>Deletar Item</S.uniqueOption> : null}
                        <S.uniqueOption color={controller.general.utilColors.linkBlue} mobileOption={true} onMouseDown={() => controller.setOptionShowing(prev => prev == 'form' ? 'comments' : 'form')}>{controller.optionShowing == 'comments' ? 'Formulário' : 'Linha do tempo'}</S.uniqueOption>
                        {props.options ? props.options.map((option, index) => (
                            <S.uniqueOption key={index} color={colors.linkBlue} onMouseDown={() => option.function()}>{option.name}</S.uniqueOption>
                        )) : null}
                        {props.convertLeadToClient && <S.uniqueOption color={controller.general.utilColors.linkBlue} onMouseDown={() => controller.setConvertLeadToClientConfirmationModal((active: boolean) => !active)}>Converter em cliente</S.uniqueOption>}
                        <S.uniqueOption color={controller.general.utilColors.linkBlue} onMouseDown={() => props.setModalActive((active: boolean) => !active)}>Fechar</S.uniqueOption>
                    </S.options>
                </S.container>
                <S.containerForm showItem={controller.optionShowing} onSubmit={(e) => {
                    e.preventDefault();
                }}>
                    <S.title>Informações</S.title>
                    <S.expandInfo onMouseDown={() => controller.setExpandedInfoModal(prev => !prev)}>[Expandir]</S.expandInfo>
                    <S.allFormInputs ref={controller.inputsListRef} id='inputsFormList'>
                        {controller.item.infos ? controller.item.infos.map((field: typeof controller.item.infos[0], index: number) => {
                            switch (field.type) {
                                case 'text':
                                    return <InputText setItem={controller.setItem} item={controller.item} field={field} key={index} randomName={controller.general.randomString(10)} hidden={field.hidden ?? false} />
                                case 'password':
                                    return <InputText setItem={controller.setItem} item={controller.item} field={field} key={index} randomName={controller.general.randomString(10)} type={'password'} hidden={field.hidden ?? false} />
                                case 'select':
                                    return <InputSelect setItem={controller.setItem} item={controller.item} field={field} key={controller.general.randomString(10)} randomName={controller.general.randomString(10)} hidden={field.hidden ?? false} />
                                case 'selectWithSearch':
                                    return <SelectWithSearch defaultValue={field.value?.toString() ?? ''} placeholder={field.placeholder ?? 'Pesquisar...'} isGeneralField={field?.isGeneralField ?? false} width={field?.width ?? '100%'} transparent={field?.transparent ?? false} options={field.selectOptions} executeOnSelect={controller.selectWithSearch} />
                                case 'datetime':
                                    return <DateTime setItem={controller.setItem} item={controller.item} field={field} key={controller.general.randomString(10)} randomName={controller.general.randomString(10)} hidden={field.hidden ?? false} />
                                case 'date':
                                    return <DateTime setItem={controller.setItem} item={controller.item} field={field} key={controller.general.randomString(10)} randomName={controller.general.randomString(10)} hidden={field.hidden ?? false} />
                                case 'checkbox':
                                    return <Checkbox setItem={controller.setItem} item={controller.item} field={field} key={controller.general.randomString(10)} randomName={controller.general.randomString(10)} hidden={field.hidden ?? false} />
                                default:
                                    return <InputText setItem={controller.setItem} item={controller.item} field={field} key={index} randomName={controller.general.randomString(10)} type={field.type ?? 'text'} hidden={field.hidden ?? false} />
                            }
                        }) : null}
                    </S.allFormInputs>
                    {props.canAddCustomFields == true ? <S.buttonAddField onMouseDown={(e) => {
                        e.preventDefault();
                        controller.addNewItemField()
                    }}>Adicionar campo</S.buttonAddField> : null}
                    <S.button onClick={() => {
                        controller.sendRequest(true)
                    }}>
                        <controller.general.DefaultLoadingFit active={controller.loading} borderRadius={5} size={15} />
                        Salvar
                    </S.button>
                    {props.stockIncrement ?
                        <S.button onClick={(e) => {
                            e.preventDefault();
                            controller.setIncrementStockModal(true)
                        }}>
                            Incrementar estoque
                        </S.button>
                        : null
                    }
                </S.containerForm>
                <S.container showItem={controller.optionShowing}>
                    <S.commentSection>
                        <S.title>Comentários</S.title>
                        <S.allComments>
                            {(controller.item?.timeline ?? []).length > 0 ?
                                (controller.item?.timeline ?? []).map((item: any, index: number) => (
                                    <S.comment key={`comment_${index}`}>
                                        <S.commentHeader>
                                            {item.avatar_url || item.fromSystem ? <S.commentAvatar src={item.fromSystem ? `${process.env.REACT_APP_BASE_URL}app/images/logo/logo_arezdot_icon.ico` : item.avatar_url} /> : <Avatar textSizeRatio={2.25} round='20px' size='40px' name={item.title} style={{ alignSelf: 'center' }} />}
                                            <S.commentItemInfo>
                                                <S.commentName>{item.title}</S.commentName>
                                                <S.commentDate>{`${item.createdAt?.split('T')[0].split('-').reverse().join('/')} ${item.createdAt?.split('T')[1].substring(0, 5)}`}</S.commentDate>
                                            </S.commentItemInfo>
                                        </S.commentHeader>
                                        <S.commentContent>{item.message}</S.commentContent>
                                    </S.comment>
                                ))
                                :
                                <S.noComments>
                                    <S.noCommentsIcon icon={general.icons.faCommentSlash} />
                                    <S.noCommentsText>Nenhum comentário</S.noCommentsText>
                                </S.noComments>
                            }
                        </S.allComments>
                        <S.sendCommentSection onSubmit={() => controller.sendComment()}>
                            <S.commentToSendInput onKeyDown={(e: any) => {
                                if (e.key == 'Enter' && controller.commentInput.trim() != '' && !e.shiftKey) {
                                    e.preventDefault();
                                    controller.sendComment();
                                }
                            }} value={controller.commentInput} onChange={(e) => controller.setCommentInput(e.target.value)} placeholder='Escreva um comentário...' />
                            <S.commentSendButton onMouseDown={() => controller.sendComment()} className='sendCommentBtn' icon={general.icons.faChevronRight} />
                        </S.sendCommentSection>
                    </S.commentSection>
                </S.container>
            </S.modal>
        </S.main>
    );
}

export default DefaultEditModal;