import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../utils/types';
import MenuComponent from '../../../components/app/MenuComponent';
import HeaderBarComponent from '../../../components/app/HeaderBarComponent';
import ReactPlayer from 'react-player';

const Onboarding = (props: defaultProps) => {
    const controller = useController();

    return (
        <S.main>
            {controller.videoOpenedModal && (
                <S.videoModalWrapper onMouseDown={() => controller.setVideoOpenedModal(false)}>
                    <S.videoModal onMouseDown={(e) => {
                        e.stopPropagation();
                    }}>
                        <S.videoModalHeader>
                            <S.videoModalTitle>{controller.videos[controller.videoSelected].title}</S.videoModalTitle>
                            <S.closeVideoIcon icon={controller.general.icons.faTimes} onClick={() => controller.setVideoOpenedModal(false)} />
                        </S.videoModalHeader>
                        <S.rcPlayer
                            url={controller.videos[controller.videoSelected].path}
                            controls={true}
                            width='auto'
                            height='90%'
                            className='react-player'
                        />
                        <S.rcPlayerMobile
                            url={controller.videos[controller.videoSelected].path}
                            controls={true}
                            width='auto'
                            height='80%'
                            className='react-player'
                        />
                    </S.videoModal>
                </S.videoModalWrapper>
            )}
            <MenuComponent menuPreset={localStorage.getItem('company') ? 1 : 0} />
            <S.generalArea>
                <HeaderBarComponent pageName='Treinamento' onSearchChange={controller.onSearchChange} />
                <S.operationsArea>
                    {controller.showingVideos.map((video, index) => (
                        <S.onboardingVideo index={index} onClick={() => {
                            controller.setVideoSelected(video.id);
                            controller.setVideoOpenedModal(true);
                        }}>
                            <S.videoImageWrapper>
                                <S.videoImage src={video.thumbnail} alt={'Imagem de thumbnail do video com o título: ' + video.title} />
                                <S.playButton icon={controller.general.icons.faPlayCircle} />
                            </S.videoImageWrapper>
                            <S.videoTitle>{video.title}</S.videoTitle>
                        </S.onboardingVideo>
                    ))}
                </S.operationsArea>
            </S.generalArea>
        </S.main>
    );
}

export default Onboarding;