import React from "react";
import { Routes, Route } from "react-router-dom";
import general from "./utils/general";

//app routes
import AccountEdit from "./pages/app/AccountEdit";
import Bills from "./pages/app/Bills";
import ChooseCompany from "./pages/app/ChooseCompany";
import CompanyConfig from "./pages/app/CompanyConfig";
import Dashboard from "./pages/app/Dashboard";
import Flows from "./pages/app/Flows";
import FlowArea from "./pages/app/Flows/FlowArea";
import Home from "./pages/app/Home";
import Login from "./pages/app/Login";
import Page404 from "./pages/app/Page404";
import ProdServiceCategories from "./pages/app/ProdServiceCategories";
import Products from "./pages/app/Products";
import Services from "./pages/app/Services";
import SignUp from "./pages/app/SignUp";
import Stakeholders from "./pages/app/Stakeholders";
import StoreEdit from "./pages/app/StoreEdit";
import Finances from "./pages/app/Finances";
import HumanResources from "./pages/app/HumanResources";
import Suppliers from "./pages/app/Suppliers";
import Agenda from "./pages/app/Agenda";
import Forms from "./pages/app/Forms";
import EditForm from "./pages/app/Forms/EditForm";
import PublicForm from "./pages/app/Forms/PublicForm";
import Stores from "./pages/app/Stores";
import Utilities from "./pages/app/Utilities";
import Teams from "./pages/app/Teams";
import Leads from "./pages/app/Leads";
import Goals from "./pages/app/Goals";
import Vehicles from "./pages/app/Vehicles";
import Patrimony from "./pages/app/Patrimony";
import CompletedGoals from "./pages/app/Goals/CompletedGoals";
import Automation from "./pages/app/Automation";
import Onboarding from "./pages/app/Onboarding";

//backoffice routes
import BackofficeAccounts from "./pages/backoffice/Accounts";
import BackofficeDashboard from "./pages/backoffice/Dashboard";
import BackofficeLogin from "./pages/backoffice/Login";
import Plans from "./pages/backoffice/Plans";
import PromoCode from "./pages/backoffice/PromoCodes";
import BackofficeLogs from "./pages/backoffice/Logs";
import BackofficeSystemConfigs from "./pages/backoffice/SystemConfigs";

//store routes
import StoreAccount from "./pages/store/Account";
import StoreOrderFinished from "./pages/store/OrderFinished";
import StoreOrders from "./pages/store/Orders";
import StorePage404 from "./pages/store/Page404";
import StoreShoppingCart from "./pages/store/ShoppingCart";
import StoreStore from "./pages/store/Store";

import useStore from "./utils/zustand";

const checkAuth = () => {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      return general.api.post('/account/authcheck', { token }).then((response: any) => {
        if (response.data.status === 200) {
          if (localStorage.getItem("company") !== null) {
            let currentCompany = localStorage.getItem("company")
            general.api.get('/account-company/get/' + currentCompany).then((response) => {
              if (response.status === 200) {
                let dataObject = response.data.data[0]
                localStorage.setItem('company', dataObject.company._id);
                localStorage.setItem('companyObject', JSON.stringify(dataObject.company));
              }
            }).catch((err) => {
              console.log(err);
              general.notify('Erro ao buscar empresa', 'error');
            });
          }
          localStorage.setItem("account", JSON.stringify(response.data.data));
          return true;
        }
        else if (response.data.status === 500) {
          localStorage.removeItem("token");
          localStorage.removeItem("company");
          return false;
        }
        else {
          localStorage.removeItem("token");
          localStorage.removeItem("company");
          return false;
        }
      })
    }
    catch (err) {
      return false
    }
  }
  else {
    return false;
  }
}

function App() {
  if (checkAuth()) {
    return (
      <Routes>
        {/* app */}
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<Dashboard />} />
        <Route path="/*" element={<Page404 />} />
        <Route path="/login" element={<Dashboard />} />
        <Route path="/signup" element={<Dashboard />} />
        <Route path="/app/account/edit" element={<AccountEdit />} />
        <Route path="/app/bills" element={<Bills />} />
        <Route path="/app/company/choose" element={<ChooseCompany />} />
        <Route path="/app/company/config" element={<CompanyConfig />} />
        <Route path="/app/dashboard" element={<Dashboard />} />
        <Route path="/app/flows" element={<Flows />} />
        <Route path="/app/flow/:id" element={<FlowArea />} />
        <Route path="/app/prodserv/categories" element={<ProdServiceCategories />} />
        <Route path="/app/products" element={<Products />} />
        <Route path="/app/services" element={<Services />} />
        <Route path="/app/stakeholders" element={<Stakeholders />} />
        <Route path="/app/store/:store_id" element={<StoreEdit />} />
        <Route path="/app/finances" element={<Finances />} />
        <Route path="/app/humanresources" element={<HumanResources />} />
        <Route path="/app/suppliers" element={<Suppliers />} />
        <Route path="/app/agenda" element={<Agenda />} />
        <Route path="/app/forms" element={<Forms />} />
        <Route path="/app/form/options/:slug" element={<EditForm />} />
        <Route path="/app/stores" element={<Stores />} />
        <Route path="/app/utilities" element={<Utilities />} />
        <Route path="/app/teams" element={<Teams />} />
        <Route path="/app/leads" element={<Leads />} />
        <Route path="/app/vehicles" element={<Vehicles />} />
        <Route path="/app/patrimony" element={<Patrimony />} />
        <Route path="/app/goals" element={<Goals />} />
        <Route path="/app/goals/completed" element={<CompletedGoals />} />
        <Route path="/app/automation" element={<Automation />} />
        <Route path="/app/onboarding" element={<Onboarding />} />

        {/* Public routes (no login needed) */}
        <Route path="/io/form/:slug" element={<PublicForm />} />

        {/* backoffice */}
        <Route path="/backoffice" element={<BackofficeAccounts />} />
        <Route path="/backoffice/*" element={<Page404 />} />
        <Route path="/backoffice/dashboard" element={<BackofficeDashboard />} />
        <Route path="/backoffice/accounts" element={<BackofficeAccounts />} />
        <Route path="/backoffice/plans" element={<Plans />} />
        <Route path="/backoffice/promo_codes" element={<PromoCode />} />
        <Route path="/backoffice/companies" element={<PromoCode />} />
        <Route path="/backoffice/system/logs" element={<BackofficeLogs />} />
        <Route path="/backoffice/system/configs" element={<BackofficeSystemConfigs />} />
        <Route path="/backoffice/onboarding" element={<Onboarding />} />

        {/* store */}
        <Route path="/store/client/account/:stakeholder_id" element={<StoreAccount />} />
        <Route path="/store/*" element={<StorePage404 />} />
        <Route path="/store/:store_slug" element={<StoreStore />} />
        <Route path="/store/:store_slug/orders" element={<StoreOrders />} />
        <Route path="/store/:store_slug/order/finished" element={<StoreOrderFinished />} />
        <Route path="/store/:store_slug/shoppingcart" element={<StoreShoppingCart />} />
      </Routes>
    );
  }
  else {
    return (
      <Routes>
      {/* app */}
      {/* <Route path="/" element={<Home />} /> */}
      <Route path="/" element={<Login />} />
      <Route path="/*" element={<Page404 />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/app/account/edit" element={<AccountEdit />} />
      <Route path="/app/bills" element={<Bills />} />
      <Route path="/app/company/choose" element={<Login />} />
      <Route path="/app/company/config" element={<Login />} />
      <Route path="/app/dashboard" element={<Login />} />
      <Route path="/app/flows" element={<Login />} />
      <Route path="/app/flow/:id" element={<Login />} />
      <Route path="/app/prodserv/categories" element={<Login />} />
      <Route path="/app/products" element={<Login />} />
      <Route path="/app/services" element={<Login />} />
      <Route path="/app/stakeholders" element={<Login />} />
      <Route path="/app/store/:store_id" element={<Login />} />
      <Route path="/app/finances" element={<Login />} />
      <Route path="/app/humanresources" element={<Login />} />
      <Route path="/app/suppliers" element={<Login />} />
      <Route path="/app/agenda" element={<Login />} />
      <Route path="/app/forms" element={<Login />} />
      <Route path="/app/form/options/:slug" element={<Login />} />
      <Route path="/app/stores" element={<Login />} />
      <Route path="/app/utilities" element={<Login />} />
      <Route path="/app/teams" element={<Login />} />
      <Route path="/app/leads" element={<Login />} />
      <Route path="/app/vehicles" element={<Login />} />
      <Route path="/app/patrimony" element={<Login />} />
      <Route path="/app/goals" element={<Login />} />
      <Route path="/app/goals/completed" element={<Login />} />
      <Route path="/app/automation" element={<Login />} />
      <Route path="/app/onboarding" element={<Login />} />

      {/* Public routes (no login needed) */}
      <Route path="/io/form/:slug" element={<PublicForm />} />

      {/* backoffice */}
      <Route path="/backoffice" element={<BackofficeLogin />} />
      <Route path="/backoffice/*" element={<Page404 />} />
      <Route path="/backoffice/dashboard" element={<BackofficeLogin />} />
      <Route path="/backoffice/accounts" element={<BackofficeLogin />} />
      <Route path="/backoffice/plans" element={<BackofficeLogin />} />
      <Route path="/backoffice/promo_codes" element={<BackofficeLogin />} />
      <Route path="/backoffice/companies" element={<BackofficeLogin />} />
      <Route path="/backoffice/system/logs" element={<BackofficeLogin />} />
      <Route path="/backoffice/system/configs" element={<BackofficeLogin />} />
      <Route path="/backoffice/onboarding" element={<BackofficeLogin />} />

      {/* store */}
      <Route path="/store/client/account/:stakeholder_id" element={<StoreAccount />} />
      <Route path="/store/*" element={<StorePage404 />} />
      <Route path="/store/:store_slug" element={<StoreStore />} />
      <Route path="/store/:store_slug/orders" element={<StoreOrders />} />
      <Route path="/store/:store_slug/order/finished" element={<StoreOrderFinished />} />
      <Route path="/store/:store_slug/shoppingcart" element={<StoreShoppingCart />} />
    </Routes>
    )
  }
}

export default App;
