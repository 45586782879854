import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import SelectWithSearch from '../Inputs/SelectWithSearch';
/* @ts-ignore */
import BarcodeReader from 'react-barcode-reader';
import NfcupomTemplate from './print/nfcupom';
import WarrantyTemplate from './print/warranty';
import moment from 'moment';

const CreateFinancialMovementModal = (props: any) => {
    const controller = useController(props);

    return (
        <>
            <S.printArea id='printArea'>
                {controller.printOption == controller.PrintOptions.NFCe && null}
                {controller.printOption == controller.PrintOptions.NonFiscalCupom && (
                    <NfcupomTemplate
                        company_name={controller.general.getCompany().name}
                        company_document={controller.general.getCompany().document.replace(/[^0-9]/g, '').length >= 12 ? controller.general.customMask(controller.general.getCompany().document, '##.###.###/####-##', '#', true) : ''}
                        emitter={controller.general.formatName(controller.general.getAccount().name)}
                        current_date={moment().format('DD/MM/YYYY HH:mm:ss')}
                        client_name={controller.client?.name ? controller.general.formatName(controller.client?.name) : 'Cliente não identificado'}
                        client_document={controller.clientDocument ?? 'Documento não informado'}
                        installments={controller.installments}
                        payment_method={controller.paymentMethod}
                        items={controller.selectedItems.reverse().map((item: any) => {
                            return `
                        <tr class="top">
                            <td colspan="3">${item.name}</td>
                        </tr>
                        <tr>
                            <td>R$${controller.general.formatMoney((item.price ?? 0).toString())}</td>
                            <td style="padding-left: 2mm;">x${(item.quantity).toFixed(3).toString().replace('.', ',')} UN</td>
                            <td>R$${controller.general.formatMoney(((item.price ?? 0) * item.quantity).toString())}</td>
                        </tr>
                        `
                        }).reverse().join('')}
                        sub_total_value={controller.general.formatMoney(controller.totalValue)}
                        service_fee={`R$ ${controller.general.formatMoney((controller.serviceFee ?? '0').toString())}`}
                        discount='0.00%'
                        total_value={controller.general.formatMoney(controller.totalValue)}
                    />
                )}
                {controller.printOption == controller.PrintOptions.Warranty && (
                    <WarrantyTemplate
                        company_name={controller.general.getCompany().name}
                        company_document={controller.general.getCompany().document.replace(/[^0-9]/g, '').length >= 12 ? controller.general.customMask(controller.general.getCompany().document, '##.###.###/####-##', '#', true) : ''}
                        emitter={controller.general.formatName(controller.general.getAccount().name)}
                        current_date={moment().format('DD/MM/YYYY HH:mm:ss')}
                        client_name={controller.client?.name ? controller.general.formatName(controller.client?.name) : 'Cliente não identificado'}
                        client_document={controller.clientDocument ?? 'Documento não informado'}
                        warranty_time={controller.warrantyTime ?? 0}
                        items={controller.selectedItems?.reverse()?.map((item: any) => {
                            return `
                        <tr class="top">
                            <td colspan="3">${item.name}</td>
                        </tr>
                        <tr>
                            <td>R$${controller.general.formatMoney((item.price ?? 0).toString())}</td>
                            <td style="padding-left: 2mm;">x${(item.quantity).toFixed(3).toString().replace('.', ',')} UN</td>
                            <td>R$${controller.general.formatMoney(((item.price ?? 0) * item.quantity).toString())}</td>
                        </tr>
                        `
                        }).reverse().join('')}
                        sub_total_value={controller.general.formatMoney(controller.totalValue)}
                        service_fee={`R$ ${controller.general.formatMoney((controller.serviceFee ?? '0').toString())}`}
                        discount='0.00%'
                        total_value={controller.general.formatMoney(controller.totalValue)}
                    />
                )}
            </S.printArea>
            <S.main active={controller.modalActive} animationOpacity={props.modalActive}>
                <S.modal step={controller.step} onClick={(e) => e.stopPropagation()} animationInOut={props.modalActive}>
                    <S.header>
                        <S.title>Criar Movimentação financeira - {controller.stepName[controller.step].title}</S.title>
                        <S.close onClick={() => {
                            controller.setStep(0);
                            props.setModalActive((active: boolean) => !active)
                        }}>X</S.close>
                    </S.header>
                    <S.body>
                        <S.stepContainer step={controller.step}>
                            <BarcodeReader onScan={(e: any) => {
                                controller.readBarcode(e)
                                if(controller.step != 1) {
                                    controller.setStep(1);
                                }
                            }} />
                            {controller.step == 0 ?
                                <S.step step={controller.step} active={controller.step == 0} mobileMod={true}>
                                    <S.movementTypeContainer>
                                        <S.movementType selected={controller.movementType == 1} onClick={() => {
                                            if (controller.movementType == 1) {
                                                controller.changeStep('next');
                                            }
                                            controller.setMovementType(1);
                                        }}>
                                            <S.movementTypeIcon icon={controller.general.icons.faArrowUp} />
                                            <S.movementTypeTitle>Entrada</S.movementTypeTitle>
                                            <S.movementTypeDescription>Selecione essa opção para registrar uma entrada de dinheiro</S.movementTypeDescription>
                                        </S.movementType>
                                        <S.movementType selected={controller.movementType == 0} onClick={() => {
                                            if (controller.movementType == 0) {
                                                controller.changeStep('next');
                                            }
                                            controller.setMovementType(0);
                                        }}>
                                            <S.movementTypeIcon icon={controller.general.icons.faArrowDown} />
                                            <S.movementTypeTitle>Saída</S.movementTypeTitle>
                                            <S.movementTypeDescription>Selecione essa opção para registrar uma saída de dinheiro</S.movementTypeDescription>
                                        </S.movementType>
                                    </S.movementTypeContainer>
                                </S.step>
                                : null}
                            {controller.step == 1 ?
                                <S.step step={controller.step} active={controller.step == 1} mobileMod={true}>
                                    <div className='selectWithSearchFinancesModal'>
                                        <SelectWithSearch instaShow={true} placeholder='Pesquise ou escaneie um código de barras...' loading={controller.loadingItems} type="withDescription" clearOnClick executeOnSelect={controller.executeOnItemSelect} options={controller.productsAndServices} />
                                    </div>
                                    {controller.selectedItems.length > 0 ?
                                        <S.movementItemsContainer>
                                            <S.totalValueContainer>
                                                <S.totalValue>Total - R$ {controller.general.formatMoney(controller.totalValue.toString())}</S.totalValue>
                                            </S.totalValueContainer>
                                            {(controller.selectedItems ?? []).map((item: any) => (
                                                <S.movementItem key={controller.general.randomString(20)}>
                                                    <S.itemImage src={item.image_url} />
                                                    <S.itemContainer>
                                                        <S.itemInfos>
                                                            <S.itemTitle>{item.name}</S.itemTitle>
                                                            <S.deleteItemIconMobile onMouseDown={() => controller.deleteItem(item)} icon={controller.general.icons.faTrash} />
                                                            <S.itemDescription>{item.description}</S.itemDescription>
                                                        </S.itemInfos>
                                                        <S.itemInputs>
                                                            <S.itemInput>
                                                                <S.itemInputLabel>Quantidade:</S.itemInputLabel>
                                                                <S.itemInputField defaultValue={item.quantity} type='number' onKeyDown={(e: any) => {
                                                                    if (e.key == 'Enter') {
                                                                        e.preventDefault();
                                                                        e.target.blur();
                                                                    }
                                                                }} onBlur={(e) => controller.changeItemQuantity(parseInt(e.target.value), item)} />
                                                            </S.itemInput>
                                                            <S.itemInput>
                                                                <S.itemInputLabel>Valor unitário:</S.itemInputLabel>
                                                                <S.itemInputField defaultValue={controller.general.formatMoney((controller.movementType == 1 ? item.price : (item.buy_price ?? 0)).toString())} onBlur={(e) => controller.changeItemPrice(e.target.value, item)} onChange={(e) => e.target.value = controller.general.formatMoney(e.target.value)} />
                                                            </S.itemInput>
                                                        </S.itemInputs>
                                                    </S.itemContainer>
                                                    <S.deleteItemIcon onMouseDown={() => controller.deleteItem(item)} icon={controller.general.icons.faTrash} />
                                                </S.movementItem>
                                            ))}
                                        </S.movementItemsContainer>
                                        :
                                        <S.noItemsContainer>
                                            <S.noItemsIcon icon={controller.general.icons.faExclamationTriangle} />
                                            <S.noItemsTitle>Nenhum item adicionado</S.noItemsTitle>
                                            <S.noItemsDescription>Selecione um item ou utilize um leitor de código de barras para adicioná-lo movimentação financeira</S.noItemsDescription>
                                        </S.noItemsContainer>
                                    }
                                </S.step>
                                : null}

                            {controller.step == 2 ?
                                <S.step step={controller.step} active={controller.step == 2} mobileMod={true}>
                                    <S.inputsContainer>
                                        <S.itemInput>
                                            <S.itemInputLabel>Título*:</S.itemInputLabel>
                                            <S.itemInputField value={controller.title} onChange={(e) => controller.setTitle(e.target.value)} />
                                        </S.itemInput>
                                        <S.itemInput>
                                            <S.itemInputLabel>Moeda*:</S.itemInputLabel>
                                            <div className='selectWithSearchCurrency'>
                                                <SelectWithSearch defaultValue='BRL (R$)' executeOnSelect={controller.executeOnCurrencySelect} options={
                                                    controller.general.getCountries().reduce((countries: any, item: any) => {
                                                        if (item.currency != null && item.currency != undefined && item.currency != '') {
                                                            if (!countries.find((country: any) => country.value == item.currency)) {
                                                                countries.push({
                                                                    name: `${item.currency} (${item.currencySymbol})`,
                                                                    value: item.currency
                                                                });
                                                            }
                                                        }
                                                        return countries;
                                                    }, []).sort((a: any, b: any) => a.name.localeCompare(b.name))} />
                                            </div>
                                        </S.itemInput>
                                        <S.itemInput>
                                            <S.itemInputLabel>Valor total*:</S.itemInputLabel>
                                            <S.itemInputField value={controller.general.formatMoney(controller.totalValue)} onChange={(e) => controller.setTotalValue(e.target.value)} />
                                        </S.itemInput>
                                        <S.itemInput>
                                            <S.itemInputLabel>Parcelas:</S.itemInputLabel>
                                            <S.itemInputField type='number' value={controller.installments} onChange={(e) => controller.setInstallments(parseInt(e.target.value))} />
                                        </S.itemInput>
                                        <S.itemInput>
                                            <S.itemInputLabel>Taxa de serviço (%)*:</S.itemInputLabel>
                                            <S.itemInputField value={controller.general.formatMoney(controller.serviceFee) ?? controller.general.formatMoney('0')} onChange={(e) => controller.setServiceFee(e.target.value)} />
                                        </S.itemInput>
                                        <S.itemInput>
                                            <S.itemInputLabel>{controller.movementType == 1 ? 'Cliente' : 'Vendedor'} (opcional):</S.itemInputLabel>
                                            <div className='selectWithSearchCurrency'>
                                                <SelectWithSearch defaultValue={controller.client?.name ?? ''} clearOnClick={false} executeOnSelect={controller.selectClient} options={
                                                    ([{
                                                        name: 'Cliente não identificado',
                                                        value: null
                                                    }, ...controller.companyClients]).reduce((clients: any, item: any) => {
                                                        clients.push({
                                                            name: `${item.name}`,
                                                            value: item._id ?? null
                                                        });
                                                        return clients;
                                                    }, [])} />
                                            </div>
                                        </S.itemInput>
                                        <S.itemInput>
                                            <S.itemInputLabel>CPF ou CNPJ do cliente (opcional):</S.itemInputLabel>
                                            <S.itemInputField value={controller.general.customMask(controller.clientDocument, (controller.clientDocument.replace(/[^0-9]/g, '').length >= 12 ? '##.###.###/####-##' : '###.###.###-##'), "#", true)} onChange={(e) => controller.setClientDocument(e.target.value)} />
                                        </S.itemInput>
                                        <S.itemInput>
                                            <S.itemInputLabel>Data prevista (opcional):</S.itemInputLabel>
                                            <S.itemInputField value={controller.general.customMask(controller.dueAt, "##/##/####", '#', true)} onChange={(e) => controller.setDueAt(e.target.value)} />
                                        </S.itemInput>
                                        <S.itemInput>
                                            <S.itemInputLabel>Data de pagamento (opcional):</S.itemInputLabel>
                                            <S.itemInputField value={controller.general.customMask(controller.paidAt, "##/##/####", '#', true)} onChange={(e) => controller.setPaidAt(e.target.value)} />
                                        </S.itemInput>
                                        <S.itemInput>
                                            <S.itemInputLabel>Dias de garantia (opcional):</S.itemInputLabel>
                                            <S.itemInputField type='number' value={controller.warrantyTime ?? '0'} onChange={(e) => (parseInt(e.target.value) ?? 0) < 0 ? 0 : controller.setWarrantyTime(parseInt(e.target.value) ?? 0)} />
                                        </S.itemInput>
                                    </S.inputsContainer>
                                </S.step>
                                : null}

                            {controller.step == 3 ?
                                <S.step step={controller.step} active={controller.step == 3} mobileMod={true}>
                                    <S.paymentMethodContainer>
                                        <S.paymentMethodBit selected={controller.paymentMethod == 0} onClick={() => {
                                            if (controller.paymentMethod == 0) controller.changeStep('next');
                                            controller.setPaymentMethod(0);
                                        }}>
                                            <S.paymentMethodIcon icon={controller.general.icons.faCreditCard} />
                                            <S.paymentMethodTitle>Cartão de crédito</S.paymentMethodTitle>
                                        </S.paymentMethodBit>
                                        <S.paymentMethodBit selected={controller.paymentMethod == 1} onClick={() => {
                                            if (controller.paymentMethod == 1) controller.changeStep('next');
                                            controller.setPaymentMethod(1);
                                        }}>
                                            <S.paymentMethodIcon icon={controller.general.icons.faCreditCard} />
                                            <S.paymentMethodTitle>Cartão de débito</S.paymentMethodTitle>
                                        </S.paymentMethodBit>
                                        <S.paymentMethodBit selected={controller.paymentMethod == 2} onClick={() => {
                                            if (controller.paymentMethod == 2) controller.changeStep('next');
                                            controller.setPaymentMethod(2);
                                        }}>
                                            <S.paymentMethodIcon icon={controller.general.icons.faReceipt} />
                                            <S.paymentMethodTitle>Voucher</S.paymentMethodTitle>
                                        </S.paymentMethodBit>
                                        <S.paymentMethodBit selected={controller.paymentMethod == 3} onClick={() => {
                                            if (controller.paymentMethod == 3) controller.changeStep('next');
                                            controller.setPaymentMethod(3);
                                        }}>
                                            <S.paymentMethodIcon icon={controller.general.icons.faMoneyBill} />
                                            <S.paymentMethodTitle>Dinheiro</S.paymentMethodTitle>
                                        </S.paymentMethodBit>
                                        <S.paymentMethodBit selected={controller.paymentMethod == 4} onClick={() => {
                                            if (controller.paymentMethod == 4) controller.changeStep('next');
                                            controller.setPaymentMethod(4);
                                        }}>
                                            <S.paymentMethodIcon icon={controller.general.icons.faQrcode} />
                                            <S.paymentMethodTitle>PIX</S.paymentMethodTitle>
                                        </S.paymentMethodBit>
                                        <S.paymentMethodBit selected={controller.paymentMethod == 6} onClick={() => {
                                            if (controller.paymentMethod == 6) controller.changeStep('next');
                                            controller.setPaymentMethod(6);
                                        }}>
                                            <S.paymentMethodIcon icon={controller.general.icons.faFileInvoice} />
                                            <S.paymentMethodTitle>Boleto</S.paymentMethodTitle>
                                        </S.paymentMethodBit>
                                        <S.paymentMethodBit selected={controller.paymentMethod == 5} onClick={() => {
                                            if (controller.paymentMethod == 5) controller.changeStep('next');
                                            controller.setPaymentMethod(5);
                                        }}>
                                            <S.paymentMethodIcon icon={controller.general.icons.faExchangeAlt} />
                                            <S.paymentMethodTitle>Transferência</S.paymentMethodTitle>
                                        </S.paymentMethodBit>
                                        <S.paymentMethodBit selected={controller.paymentMethod == 7} onClick={() => {
                                            if (controller.paymentMethod == 7) controller.changeStep('next');
                                            controller.setPaymentMethod(7);
                                        }}>
                                            <S.paymentMethodIcon icon={controller.general.icons.faEllipsisH} />
                                            <S.paymentMethodTitle>Outro</S.paymentMethodTitle>
                                        </S.paymentMethodBit>
                                    </S.paymentMethodContainer>
                                </S.step>
                                : null}

                            {controller.step == 4 ?
                                <S.step step={controller.step} active={controller.step == 4} mobileMod={true}>
                                    <S.inputsContainer>
                                        <S.fiscalNotesContainer>
                                            <S.noteTypeCard disabled selected={false} onClick={() => {
                                                controller.general.notify('Em breve', 'info');
                                                return;
                                                controller.setPrintOption(controller.PrintOptions.NFCe);
                                                setTimeout(() => {
                                                    window.print();
                                                }, 200);
                                            }}>
                                                <S.noteTypeIcon icon={controller.general.icons.faFileInvoice} />
                                                <S.noteTexts>
                                                    <S.noteTypeTitle>Nota fiscal eletrônica (NFC-e)</S.noteTypeTitle>
                                                    <S.noteTypeDescription>Selecione essa opção para emitir uma nota fiscal eletrônica <b>{/* (15000 restantes) */}</b></S.noteTypeDescription>
                                                </S.noteTexts>
                                            </S.noteTypeCard>
                                            <S.noteTypeCard selected={false} onClick={() => {
                                                controller.setPrintOption(controller.PrintOptions.NonFiscalCupom);
                                                setTimeout(() => {
                                                    window.print();
                                                }, 200);
                                            }}>
                                                <S.noteTypeIcon icon={controller.general.icons.faReceipt} />
                                                <S.noteTexts>
                                                    <S.noteTypeTitle>Cupom não fiscal</S.noteTypeTitle>
                                                    <S.noteTypeDescription>Selecione essa opção para emitir um cupom não fiscal</S.noteTypeDescription>
                                                </S.noteTexts>
                                            </S.noteTypeCard>
                                            <S.noteTypeCard selected={false} onClick={() => {
                                                controller.setPrintOption(controller.PrintOptions.Warranty);
                                                setTimeout(() => {
                                                    window.print();
                                                }, 200);
                                            }}>
                                                <S.noteTypeIcon icon={controller.general.icons.faMoneyCheckDollar} />
                                                <S.noteTexts>
                                                    <S.noteTypeTitle>Comprovante de garantia</S.noteTypeTitle>
                                                    <S.noteTypeDescription>Selecione essa opção para emitir um comprovante de garantia</S.noteTypeDescription>
                                                </S.noteTexts>
                                            </S.noteTypeCard>
                                            <S.noteTypeCard selected={false} onClick={() => controller.changeStep('next', false)}>
                                                <S.noteTypeIcon icon={controller.general.icons.faAnglesRight} />
                                                <S.noteTexts>
                                                    <S.noteTypeTitle>Prosseguir</S.noteTypeTitle>
                                                    <S.noteTypeDescription>Selecione essa opção para prosseguir (Não será emitido nada ao selecionar essa opção)</S.noteTypeDescription>
                                                </S.noteTexts>
                                            </S.noteTypeCard>
                                        </S.fiscalNotesContainer>
                                    </S.inputsContainer>
                                </S.step>
                                : null}
                        </S.stepContainer>
                        <S.progressBar>
                            <S.progress percentage={controller.calculateStepPercentage()}></S.progress>
                            <S.progressPercentage>
                                {parseInt(controller.calculateStepPercentage().toString()) + '%'}
                            </S.progressPercentage>
                        </S.progressBar>
                        <S.nextBackBtns>
                            {controller.step != 4 ? (
                                <S.nextBackBtn onClick={() => controller.changeStep('next')}>
                                    <controller.general.DefaultLoadingFit active={controller.loading} borderRadius={4} size={20} />
                                    {controller.stepName[controller.step].nextBtn ? controller.stepName[controller.step].nextBtn : ''}
                                </S.nextBackBtn>
                            ) : null}
                            {(controller.step == 0 || controller.step == 4) ? null : <S.nextBackBtn onClick={() => controller.changeStep('back')}>{controller.stepName[controller.step].backBtn ? controller.stepName[controller.step].backBtn : ''}</S.nextBackBtn>}
                        </S.nextBackBtns>
                    </S.body>
                </S.modal>
            </S.main>
        </>
    );
}

export default CreateFinancialMovementModal;