import React, { useRef } from 'react';
import { useController } from './controller';
import * as S from './styles';
import general from '../../../../utils/general';
import Calendar from '../../../../components/app/Calendar';
import moment from 'moment-timezone';

const DateTime = (props: any) => {
    const controller = useController(props);
    const inputRef = useRef<HTMLInputElement>(null);
    const [inputValue, setInputValue] = React.useState(props.field.value ?? '');

    const alterInput = (value: any) => {
        const d = value
        if(props.field.type != 'date') {
            const date = moment(`${d.year.toString().padStart(2, '0')}-${(d.month).toString().padStart(2, '0')}-${d.day}T${d.hour.toString().padStart(2, '0')}:${d.minute.toString().padStart(2, '0')}`).tz('America/Sao_Paulo').toDate();
            setInputValue(moment(date).tz('America/Sao_Paulo').format('YYYY-MM-DDTHH:mm'));
        }
        else {
            const date = moment(`${d.year.toString().padStart(2, '0')}-${(d.month).toString().padStart(2, '0')}-${d.day}`).tz('America/Sao_Paulo').toDate();
            setInputValue(moment(date).tz('America/Sao_Paulo').format('YYYY-MM-DD'));
        }
    }

    return (
        <>
            {controller.showDatePicker && <Calendar 
            calendarType={props.field.type ?? 'datetime'} 
            closeOnDateSelect={props.field.type == 'date' ? true : false} 
            onDateTimeChange={alterInput} 
            startValue={inputValue} 
            height={{
                pc: 'calc(100% - 2px)',
                tablet: 'calc(100% - 2px)',
                mobile: 'calc(100% - 2px)'
            }} 
            setOpen={controller.setShowDatePicker}/>}
            <S.inputContainer key={Math.random()}>
                {/* props.field.name?.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/ /g, '_') ?? props.randomName */}
                <S.input autoComplete='off' placeholder='Digite um nome para o campo' id={`key_${props.field.key}`} width={(props.item.infos?.filter((item: any) => item.key == props.field.key)[0].name.length ?? 0) + 2} onChange={(e) => controller.handleNameChange(e, props.randomName)} name={`key_${props.field.key}`} defaultValue={`${props.field.name ?? props.randomName}${props.field.required ? '*' : ''}`} disabled={props.field.disabled && props.field.disabled == true ? true : false} />
                <S.inputDate hidden ref={inputRef} type={props.field.type == 'datetime' ? 'datetime-local' : 'date'} autoComplete='off' onChange={(e) => {
                    controller.handleChange(e);
                    setInputValue(e.target.value);
                }} name={`value_${props.field.key}`} value={inputValue} id={`value_${props.field.key}`} />
                <S.valueDiv onClick={() => controller.setShowDatePicker(true)}>
                    <S.value>{inputValue ? `${props.field.type == 'date' ? moment(inputValue).tz('America/Sao_Paulo').format('DD/MM/YYYY') : moment(inputValue).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm')}` : 'Clique para selecionar'}</S.value>
                    <S.icon icon={general.icons.faCalendarAlt} />
                </S.valueDiv>
                {!props.field.disabled ?
                    <S.deleteIcon icon={general.icons.faTrash} onClick={() => {
                        controller.removeItem();
                    }} />
                    : null
                }
            </S.inputContainer>
        </>
    );
}

export default DateTime;