import general from '../../../utils/general';
import { useEffect, useState } from 'react';
export const useController = () => {
    const [videoOpenedModal, setVideoOpenedModal] = useState(false);
    const [videoSelected, setVideoSelected] = useState(0);
    const [videos, setVideos] = useState([
        {
            id: 0,
            title: 'Configurações, Suporte e Notificações',
            path: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/videos/Configurações, suporte e notificações (Gustavo).mp4',
            thumbnail: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/images/Configurações, suporte e notificações.webp'
        },
        {
            id: 1,
            title: 'Colaboradores: Configurando Usuários e Permissões',
            path: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/videos/Colaboradores e usuários do sistema (Gustavo).mp4',
            thumbnail: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/images/Colaboradores e usuários do sistema.webp'
        },
        {
            id: 2,
            title: 'Categorias: Classificando Informações',
            path: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/videos/Categorias (Gustavo).mp4',
            thumbnail: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/images/Categorias.webp'
        },
        {
            id: 3,
            title: 'Clientes: Cadastro e Organização',
            path: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/videos/Clientes (Gustavo).mp4',
            thumbnail: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/images/Clientes.webp'
        },
        {
            id: 4,
            title: 'Leads: Gestão de Potenciais Clientes',
            path: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/videos/Leads (Gustavo).mp4',
            thumbnail: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/images/Leads.webp'
        },
        {
            id: 5,
            title: 'Fornecedores: Controle de Parceiros',
            path: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/videos/Fornecedores (Gustavo).mp4',
            thumbnail: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/images/Fornecedores.webp'
        },
        {
            id: 6,
            title: 'Produtos e Serviços: Cadastro e Gestão',
            path: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/videos/Produtos e Serviços (Gustavo).mp4',
            thumbnail: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/images/Produtos e Serviços.webp'
        },
        {
            id: 7,
            title: 'Formulários: Captura e Organização de Dados',
            path: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/videos/Formulários (Gustavo).mp4',
            thumbnail: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/images/Formulários.webp'
        },
        {
            id: 8,
            title: 'Agenda: Gerenciando Eventos e Atividades',
            path: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/videos/Agenda (Gustavo).mp4',
            thumbnail: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/images/Agenda.webp'
        },
        {
            id: 9,
            title: 'Fluxos: Configurando Processos Internos',
            path: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/videos/Fluxos (Gustavo).mp4',
            thumbnail: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/images/Fluxos.webp'
        },
        {
            id: 10,
            title: 'Automação: Automatizando Processos e Tarefas',
            path: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/videos/Automações (Gustavo).mp4',
            thumbnail: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/images/Automações.webp'
        },
        {
            id: 11,
            title: 'Metas: Definição e Acompanhamento',
            path: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/videos/Metas (Gustavo).mp4',
            thumbnail: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/images/Metas.webp'
        },
        {
            id: 12,
            title: 'Finanças: Controle Financeiro e Fluxo de Caixa',
            path: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/videos/Finanças (Gustavo).mp4',
            thumbnail: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/images/Finanças.webp'
        },
        {
            id: 13,
            title: 'Patrimônio: Controle de Bens e Recursos',
            path: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/videos/Patrimônio (Gustavo).mp4',
            thumbnail: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/images/Patrimônio.webp'
        },
        {
            id: 14,
            title: 'Times: Organização de Equipes',
            path: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/videos/Times (Gustavo).mp4',
            thumbnail: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/images/Times.webp'
        },
        {
            id: 15,
            title: 'Veículos: Gerenciamento de Frota',
            path: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/videos/Veículos (Gustavo).mp4',
            thumbnail: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/images/Veículos.webp'
        },
        {
            id: 16,
            title: 'Utilidades: Recursos de Apoio',
            path: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/videos/Utilidades (Gustavo).mp4',
            thumbnail: 'https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/images/Utilidades.webp'
        }
    ]);
    const [showingVideos, setShowingVideos] = useState(videos);

    const onSearchChange = (searchTerm: string) => {
        const filteredVideos = videos.filter((video) =>
            video.title.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(searchTerm.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
        );
        setShowingVideos(filteredVideos);
    };

    return {
        general,
        videoOpenedModal,
        setVideoOpenedModal,
        videoSelected,
        setVideoSelected,
        videos,
        setVideos,
        showingVideos,
        setShowingVideos,
        onSearchChange,
    }
}

export default useController;