import React, { useState } from 'react';
import { useController } from './controller';
import { addCompanyModalProps } from '../../../utils/types';
import * as S from './styles';
import { plan } from './types';

const ActivateCupomModal = (props: any) => {
    const controller = useController(props);

    return (
        <S.main active={controller.modalActive} animationOpacity={props.modalActive} onMouseDown={() => props.setModalActive((active: boolean) => !active)}>
            <S.modal step={controller.step} onMouseDown={(e) => e.stopPropagation()} animationInOut={props.modalActive}>
                <S.header>
                    <S.title>{controller.step == 0 ? "Resgatar cupom" : "Cupom resgatado"}</S.title>
                    <S.close onClick={() => {
                        controller.setStep(0);
                        props.setModalActive((active: boolean) => !active)
                    }}>X</S.close>
                </S.header>
                <S.body>
                    <S.stepContainer id="retrieveCupomDiv">
                        {controller.step == 0 ?
                            <S.step active={controller.step == 0} mobileMod={true}>
                                <S.inputsArea>
                                    <S.inputContainer>
                                        <S.inputLabel>CUPOM:</S.inputLabel>
                                        <S.input id="insertCupom" autoComplete='off' onChange={(e) => { controller.setCupom(e.target.value.toUpperCase().trim()) }} value={controller.cupom}></S.input>
                                    </S.inputContainer>
                                </S.inputsArea>
                                {controller.errorMessage != '' ? <S.errorMessage>{controller.errorMessage}</S.errorMessage> : null}
                                <S.saveBtn onClick={() => controller.tryCupom()}>
                                    <controller.general.DefaultLoadingFit active={controller.loading} borderRadius={4} size={15} />
                                    Resgatar
                                </S.saveBtn>
                            </S.step>
                            : null}

                        {controller.step == 1 ?
                            <S.step active={controller.step == 1} mobileMod={true}>
                                <S.successMessage>
                                    {controller.responseCupom?.cupom_type == "TIME_EXTENSION" ? `Parabéns! Você ganhou ${controller.responseCupom?.system_time} dias de uso grátis! 
                                    Aproveite o sistema, qualquer dúvida estamos à disposição! 🚀🚀` : `Parabéns! Você ganhou ${controller.responseCupom?.discount_percentage}% de desconto na sua próxima fatura! Aproveite o sistema, qualquer dúvida estamos à disposição! 🚀🚀`}
                                </S.successMessage>
                                <S.saveBtn onClick={() => props.setModalActive((active: boolean) => !active)}>
                                    Concluir resgate
                                </S.saveBtn>
                            </S.step>
                            : null}
                    </S.stepContainer>
                </S.body>
            </S.modal>
        </S.main>
    );
}

export default ActivateCupomModal;