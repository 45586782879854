import general from '../../../utils/general';
import { useEffect, useState } from 'react';
export const useController = () => {
    const [editModalActive, setEditModalActive] = useState(false);
    const [createModalActive, setCreateModalActive] = useState(false);
    const [currentItem, setCurrentItem] = useState<any>();
    const [accounts, setAccounts] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const accountCreateTemplate = {
        title: 'Conta',
        subtitle: 'Criar uma nova conta',
        infos: [
            { key: 'name', type: "text", name: 'Nome', value: '', disabled: true },
            { key: 'email', type: "text", name: 'E-mail', value: '', disabled: true },
            { key: 'phone', type: "number", name: 'Telefone', value: '', mask: "(##) # ####-####", disabled: true },
            { key: 'password', type: "password", name: 'Senha', value: '', disabled: true },
            { key: 'password_confirm', type: "password", name: 'Confirmação de Senha', value: '', disabled: true },
            { key: 'birthdate', type: "date", name: 'Data de Nascimento', value: '', disabled: true },
            { key: 'accountType', type: "select", name: 'Tipo de Conta', value: 2, disabled: true, selectOptions: [{ value: 1, name: 'Administrador' }, { value: 2, name: 'Cliente' }, { value: 3, name: 'Vendedor'}] },
        ]
    }

    const getAccounts = async (showLoader?: boolean) => {
        try{
            showLoader == true && setLoading(true);
            const response = await general.api.post('account/list', {
                page: page,
                type: 1
            });
            if (response.status === 200) {
                if(response.data.status == 403) {
                    general.notify('Você não tem permissão para acessar as contas', 'error');
                    setLoading(false);
                    return;
                }
                setTotalPages(response.data.data.total_pages);
                setAccounts(response.data.data.data);
                setLoading(false);
            }
        }
        catch(err) {
            console.log(err);
            general.notify('Erro ao buscar clientes', 'error');
            setLoading(false);
        }
    }

    useEffect(() => {
        if (currentItem) {
            let newCurrentItem = currentItem;
            setCurrentItem(newCurrentItem);
        }
    }, [currentItem]);

    useEffect(() => {
        getAccounts(true);
    }, [page]);

    useEffect(() => {
        getAccounts(false);
    }, [createModalActive, editModalActive]);

    return {
        general,
        editModalActive,
        setEditModalActive,
        createModalActive,
        setCreateModalActive,
        accountCreateTemplate,
        currentItem,
        setCurrentItem,
        accounts,
        page,
        setPage,
        totalPages,
        loading
    }
}

export default useController;