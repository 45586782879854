import general from '../../../utils/general';
import { useEffect, useState } from 'react';

export const useController = () => {
    //filter starting date is the start of the month and endDate is the current date
    const [filters, setFilters] = useState({
        moneyIn: true,
        moneyOut: true,
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString()?.split('T')[0],
        endDate: new Date().toLocaleDateString('pt-BR').split('/').reverse().join('-'),
        minValue: null,
        maxValue: null
    });

    const [currentItem, setCurrentItem] = useState<any>();
    const [createCompleteModalActive, setCreateCompleteModalActive] = useState(false);
    const [updateCompleteModalActive, setUpdateCompleteModalActive] = useState(false);
    const [financialMovements, setFinancialMovements] = useState([]);
    const [totalMoneyIn, setTotalMoneyIn] = useState(0);
    const [totalMoneyOut, setTotalMoneyOut] = useState(0);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [permissionBlock, setPermissionBlock] = useState(false);
    const [fromMainScreen, setFromMainScreen] = useState(false);
    const [filterAreaActive, setFilterAreaActive] = useState(false);
    const [searchText, setSearchText] = useState('');
    const financialMovementCreateTemplate = {
        title: 'Nova movimentação financeira',
        subtitle: 'Crie uma nova movimentação financeira',
        infos: [
            {
                key: 'title',
                name: 'Título',
                type: "text",
                value: '',
                disabled: true
            },
            {
                key: 'type',
                name: 'Tipo de movimentação',
                type: "select",
                value: '',
                disabled: true,
                selectOptions: [
                    {
                        name: 'Entrada',
                        value: '1'
                    },
                    {
                        name: 'Saída',
                        value: '0'
                    }
                ]
            },
            {
                key: 'currency',
                name: 'Moeda',
                type: "select",
                value: 'BRL',
                disabled: true,
                selectOptions: general.getCountries().reduce((countries: any, item: any) => {
                    if (item.currency != null && item.currency != undefined && item.currency != '') {
                        if (!countries.find((country: any) => country.value == item.currency)) {
                            countries.push({
                                name: `${item.currency} (${item.currencySymbol})`,
                                value: item.currency
                            });
                        }
                    }
                    return countries;
                }, []).sort((a: any, b: any) => a.name.localeCompare(b.name))
            },
            {
                key: 'installments',
                name: 'Parcelas',
                type: "number",
                value: '',
                disabled: true
            },
            {
                key: 'payment_method',
                name: 'Forma de pagamento',
                type: "select",
                value: '',
                disabled: true,
                selectOptions: [
                    {
                        name: 'Cartão de crédito',
                        value: '0'
                    },
                    {
                        name: 'Cartão de débito',
                        value: '1'
                    },
                    {
                        name: 'Voucher',
                        value: '2'
                    },
                    {
                        name: 'Dinheiro',
                        value: '3'
                    },
                    {
                        name: 'PIX',
                        value: '4'
                    },
                    {
                        name: 'Transferência',
                        value: '5'
                    },
                    {
                        name: 'Boleto',
                        value: '6'
                    },
                    {
                        name: 'Outro',
                        value: '7'
                    }
                ]
            },
            {
                key: 'total_value',
                name: 'Valor',
                type: "money",
                value: '',
                disabled: true
            },
            {
                key: 'due_at',
                name: 'Data prevista',
                type: "text",
                mask: "##/##/####",
                value: '',
                disabled: true
            },
            {
                key: 'paid_at',
                name: 'Pago em',
                type: "text",
                mask: "##/##/####",
                value: '',
                disabled: true
            },
        ]
    }

    const getFinancialMovements = async (showLoader?: boolean) => {
        try {
            showLoader == true && setLoading(true);
            await general.api.post('financial-movement/list', {
                ...filters,
                search: searchText,
                page: page
            }).then((response) => {
                if (response.data.status == 403) {
                    setPermissionBlock(true);
                    setLoading(false);
                    return;
                }
                if (response.status === 200) {
                    const data = response.data.data;
                    setTotalPages(data.total_pages);
                    setFinancialMovements(data.financialmovements);
                    setTotalMoneyIn(data.total_income);
                    setTotalMoneyOut(data.total_expense);
                    setLoading(false);
                }
            });
        }
        catch (e) {
            general.notify('Erro ao buscar movimentações financeiras', 'error');
            setLoading(false);
        }
    }

    useEffect(() => {
        getFinancialMovements(true);
    }, [page]);

    useEffect(() => {
        getFinancialMovements(true);

        document.addEventListener('keydown', (e) => {
            /* if (e.key == 'Enter' && !createCompleteModalActive && !updateCompleteModalActive) {
                e.preventDefault();
                setFromMainScreen(true);
                setCreateCompleteModalActive(true);
            } */
        });
    }, []);

    useEffect(() => {
        getFinancialMovements(false);
    }, [createCompleteModalActive, currentItem]);

    useEffect(() => {
        getFinancialMovements(true);
    }, [filters]);

    useEffect(() => {
        getFinancialMovements(false);
    }, [searchText]);

    const getPaymentMethod = (payment_method: number) => {
        enum PaymentMethod {
            CREDIT_CARD = 0,
            DEBIT_CARD = 1,
            VOUCHER = 2,
            CASH = 3,
            PIX = 4,
            TRANSFER = 5,
            BANK_SLIP = 6,
            OTHER = 7
        }

        switch (payment_method) {
            case PaymentMethod.CREDIT_CARD:
                return 'Cartão de crédito';
            case PaymentMethod.DEBIT_CARD:
                return 'Cartão de débito';
            case PaymentMethod.VOUCHER:
                return 'Voucher';
            case PaymentMethod.CASH:
                return 'Dinheiro';
            case PaymentMethod.PIX:
                return 'PIX';
            case PaymentMethod.TRANSFER:
                return 'Transferência';
            case PaymentMethod.BANK_SLIP:
                return 'Boleto';
            case PaymentMethod.OTHER:
                return 'Outro';
            default:
                return 'Não informado';
        }
    }

    return {
        general,
        filters,
        setFilters,
        financialMovementCreateTemplate,
        currentItem,
        setCurrentItem,
        createCompleteModalActive,
        setCreateCompleteModalActive,
        financialMovements,
        totalMoneyIn,
        totalMoneyOut,
        getFinancialMovements,
        updateCompleteModalActive,
        setUpdateCompleteModalActive,
        page,
        setPage,
        totalPages,
        setTotalPages,
        loading,
        permissionBlock,
        getPaymentMethod,
        filterAreaActive,
        setFilterAreaActive,
        fromMainScreen,
        setFromMainScreen,
        searchText,
        setSearchText
    }
}

export default useController;