import styled from 'styled-components';
import { colors } from '../../../utils/colors';
import { fonts } from '../../../utils/fonts';
import general from '../../../utils/general';
import ReactPlayer from 'react-player';

export const main = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    height: 100vh;
    background-color: ${colors.backgroundWhite};
`;
export const generalArea = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex: 1;

    @media (max-width: 1280px) {
        height: calc(100% - 60px);
    }
`;
export const operationsArea = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    grid-template-rows: auto 1fr;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    gap: 25px;
    justify-content: center;

    &::after {
        content: '';
        flex: auto;
    }

    @media (max-width: 700px) {
        display: flex;
        flex-direction: column;
        justify-content: left;
    }
`;

export const onboardingVideo = styled.div<{ index: number }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 20px 20px 0 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition-duration: 0.3s;
    transform: scale(0);
    animation popUpAnimation 0.5s ${props => props.index * 0.05}s forwards;

    &:hover {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
        transform: scale(1.015);
    }
`;

export const videoImageWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 200px;
    border-radius: 10px;
`;

export const videoImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
`;

export const playButton = styled(general.FontAwesomeIcon)`
    position: absolute;
    color: #E5CE00;
    font-size: 60px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const videoTitle = styled.h1`
    font-family: ${fonts.main};
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 30px 0;
    text-align: center;
    width: 100%;
    height: 100%;
    align-content: center;
`;

export const videoModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

export const videoModal = styled.div`
    width: 50%;
    height: 60%;
    background-color: black;
    color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    position: relative;
    overflow: hidden;
    z-index: 1001;
    transform: scale(0);
    animation popUpAnimation 0.5s 0.05s forwards;

    @media (max-width: 1366px) {
        width: 60%;
    }

    @media (max-width: 900px) {
        width: 85%;
        height: 30%;

        @media (max-height: 600px) {
            width: 50%;
            height: 80%;
        }
    }
`;

export const rcPlayer = styled(ReactPlayer)`
    width: 100%;
    height: 100%;
    border-radius: 10px;

    @media (max-width: 900px) {
        display: none;
    }
`;

export const rcPlayerMobile = styled(ReactPlayer)`
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: none;

    @media (max-width: 900px) {
        display: flex;
    }
`;

export const videoModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 90%;

    @media (max-width: 900px) {
        width: 95%;
    }
`;

export const videoModalTitle = styled.h1`
    font-family: ${fonts.main};
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;

    @media (max-width: 900px) {
        font-size: 16px;
    }
`;

export const closeVideoIcon = styled(general.FontAwesomeIcon)`
    cursor: pointer;
    font-size: 24px;
    transition-duration: 0s;

    &:hover {
        color: ${colors.red};
    }
`;